import firebase from "firebase";
import {message} from "antd";
import _ from "lodash";
import moment from 'moment';
// import { SCOPES , API_KEY, CLIENT_ID, DISCOVERY_DOCS} from '../../../env/PROJECTSCONFIGURATIONS';
import { SCOPES,API_KEY, CLIENT_ID, DISCOVERY_DOCS } from "../env/PROJECTSCONFIGURATIONS";
import {
    FETCH_SHORTLIST,
    FETCH_SHORTLIST_FAILED,
    FETCH_SHORTLIST_SUCCESSFUL,
    FETCH_SELECTED_CANDIDATE,
    FETCH_SELECTED_CANDIDATE_SUCCESSFUL,
    FETCH_SELECTED_CANDIDATE_FAILED,
    POSTS_FIELD_CHANGED,
    FETCH_POSTS_SUCCESSFUL,
    FETCH_POSTS_FAILED,
    FETCH_POSTS,
    SAVE_CANDIDATE,
    SAVE_CANDIDATE_SUCCESSFUL,
    SAVE_CANDIDATE_FAILED,
    SELECTED_POST,
    FETCH_POST_CANDIDATES,
    FETCH_POST_CANDIDATES_SUCCESSFUL,
    FETCH_POST_CANDIDATES_FAILED,
    DELETE_JOB_POST,
    DELETE_JOB_POST_SUCCESSFUL,
    CLEAR_POST_MESSAGES,
    DELETE_JOB_POST_FAILED,
    WITHDRAW_CANDIDATE,
    WITHDRAW_CANDIDATE_SUCCESSFUL,
    WITHDRAW_CANDIDATE_FAILED,
    ADD_POST,
    ADD_POST_SUCCESSFUL,
    ADD_POST_FAILED,
    CLEAR_SELECTED_POST,
    SAVE_RATINGS,
    SAVE_RATINGS_SUCCESSFUL,
    SAVE_RATINGS_FAILED,
    FETCH_SEEKER,
    FETCH_SEEKER_PATH_FAILED,
    FETCH_SEEKER_PATH,
    FETCH_SEEKER_PATH_SUCCESSFUL,
    FETCH_SEEKER_SUCCESSFUL,
    FETCH_SEEKER_FAILED,
    PUSHED_SEEKER,
    CANDIDATE_SELECTION,
    CANDIDATE_SELECTION_FAILED,
    CANDIDATE_SELECTION_SUCCESSFUL, CLEAR_POST, UPDATE_INTERVIEW_CANDIDATE_SUCCESSFUL,
    ADD_EVENT_TO_CALENDAR,
    FETCH_VETTING_RESULT,
    FETCH_VETTING_RESULT_FAIL,
    FETCH_VETTING_RESULT_SUCCESS,
    FETCH_ADMIN_POSTS,
    FETCH_ADMIN_POSTS_FAIL,
    FETCH_ADMIN_POSTS_SUCCESS,
    FETCH_CANDIDATES_EXPERIENCE,
    FETCH_CANDIDATES_EXPERIENCE_SUCCESS,
    FETCH_CANDIDATES_EXPERIENCE_FAIL,
    FETCH_CANDIDATE_GENDER,
    FETCH_AVERAGE_TIME_TO_HIRE,
    FETCHED_UPCOMING_INTERVIEWS_SUCESSFULLY,
    FETCH_UPCOMING_INTERVIEWS,
    FETCH_UPCOMING_INTERVIEWS_FAILED

} from "./Types";


export const postsFieldChanged = ({ prop, value}) => {
    return {
        type: POSTS_FIELD_CHANGED,
        payload: {prop, value}
    }
};


export const selectPost = (post) => {
    return  {
        type: SELECTED_POST,
        payload: post
    }
};

export const clearSelectPost = () => {
    return {
        type: CLEAR_SELECTED_POST
    }
};

export const fetchPosts = () => {
    return(dispatch) => {
        const userID = firebase.auth().currentUser.uid;
        dispatch({ type: FETCH_POSTS });
        firebase.firestore().collection("jobPosts").where("companyID", "==", userID)
            .onSnapshot(function(snapshot) {
                let posts = [];
                //check if query has no result
                if (snapshot.size === 0) {
                    dispatch({
                        type: FETCH_POSTS_FAILED,
                    });
                } else {
                    snapshot.forEach(function(doc) {
                        const data = doc.data();
                        posts.push(data);
                    });

                    dispatch({
                        type: FETCH_POSTS_SUCCESSFUL,
                        payload: posts
                    });
                }
            }, function(error) {
                console.log("Error getting documents: ", error);
                dispatch({
                    type: FETCH_POSTS_FAILED,
                });
            });
    }
};

export const fetchAdminPosts = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_ADMIN_POSTS });
        firebase.firestore().collection("jobPosts")
            .onSnapshot(function(snapshot) {
                let posts = [];
                if (snapshot.size === 0) {
                    dispatch({
                        type: FETCH_ADMIN_POSTS_FAIL,
                    });
                } else {
                    snapshot.forEach(function(doc) {
                        const data = doc.data();
                        posts.push(data);
                    });

                    dispatch({
                        type: FETCH_ADMIN_POSTS_SUCCESS,
                        payload: posts
                    });
                }
            }, function(error) {
                console.log("Error getting documents: ", error);
                dispatch({
                    type: FETCH_ADMIN_POSTS_FAIL,
                });
            });
    }
}



//------------------------------------------------------------- POSTS ---------------------------------------------------------------

export const addPost = ({ jobTitle, status, department, jobType, minimumExperience, jobDescription, compensation, companyLocation, companyName, email, phone, deadline, file }) => {
    //create posts ID
    const userID = firebase.auth().currentUser.uid;
    const slicedID = userID.slice(0, 5);
    const code = Math.random().toString(36).substring(6).toUpperCase();
    const postID = `${slicedID}${code}`;
    const datePosted = new Date();

    return async (dispatch) => {

        dispatch({type: ADD_POST });

        //Get directorsID link
        let fileDownloadUrl = "";
        if (file) {
            fileDownloadUrl = await base64UploadFile({ base64Data: file.data, fileType: file.type, path: "PostFile" });
        }

        firebase.firestore().collection("jobPosts").doc(postID)
            .set({
                acceptedInterviews: 0,
                active: true,
                status,
                companyID: userID,
                department,
                jobType,
                minimumExperience,
                description: jobDescription,
                compensation,
                companyLocation,
                companyName,
                datePosted,
                deadline,
                email,
                file: fileDownloadUrl,
                interviewRequests: 0,
                phone,
                postID,
                postTitle: jobTitle,
                rejectedInterviews: 0,
                selectedCandidates: 0,
                unsuccessfulCandidates: 0,
                graphicSent: false
            })
            .then(() => {
                dispatch({ type: ADD_POST_SUCCESSFUL});
                message.success("Saved Post");
                dispatch({ type: CLEAR_POST_MESSAGES});

            }).catch((error) => {
            dispatch({ type: ADD_POST_FAILED });
            message.warn("Failed to Save Post");
            console.log("job position creation failed");
            console.log(error);
            dispatch({ type: CLEAR_POST_MESSAGES });
        });
    }
};

export const updateGraphicSent = ({record, graphicSent}) => {
    return async () => {
        firebase.firestore().collection("jobPosts").doc(record.postID)
            .update({ graphicSent })
            .then(() => {
                fetchAdminPosts();
                console.log('graphic sent is updated successfull');
            })
            .catch((e) => {
                console.log(e);
            })
    }
}

export const editPost = ({ postID, jobTitle, status, department, jobType, minimumExperience, jobDescription, compensation, companyLocation, companyName, email, phone, deadline, file }) => {

    return async (dispatch) => {

        dispatch({type: ADD_POST });

        //Get directorsID link
        let fileDownloadUrl = file;
        if (!(_.isString(file))) {
            if (file) {
                fileDownloadUrl = await base64UploadFile({ base64Data: file.data, fileType: file.type, path: "PostFile" });
            }
        }

        firebase.firestore().collection("jobPosts").doc(postID)
            .update({
                status,
                department,
                jobType,
                minimumExperience,
                description: jobDescription,
                compensation,
                companyLocation,
                companyName,
                deadline,
                email,
                file: fileDownloadUrl,
                phone,
                postTitle: jobTitle
            })
            .then(() => {
                //grab candidates list in the post
                firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").get()
                    .then(snapshot => {
                        if (snapshot.size === 0) {
                            //do nothing
                            dispatch({ type: ADD_POST_SUCCESSFUL});
                            message.success("Saved Post");
                            dispatch({ type: CLEAR_POST_MESSAGES});
                        } else {
                            dispatch({ type: ADD_POST_SUCCESSFUL});
                            message.success("Saved Post");
                            dispatch({ type: CLEAR_POST_MESSAGES});
                            snapshot.forEach(doc => {
                                const candidate = doc.data();
                                const candidateID = candidate.userID;
                                firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(candidateID)
                                    .update({
                                        deadline
                                    }).then(() => {
                                        //user profile in seekers
                                        const interviewID = candidate.interviewID;
                                        firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc(interviewID)
                                            .update({
                                                [`infoBox.companyLocation`]: companyLocation,
                                                [`infoBox.companyName`]: companyName,
                                                [`infoBox.deadline`]: deadline,
                                                [`infoBox.postTitle`]: jobTitle,
                                            }).then(() => {
                                            //user profile

                                        }).catch(e => console.log(e))

                                }).catch(e => console.log(e))
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        dispatch({ type: ADD_POST_FAILED });
                        dispatch({ type: CLEAR_POST_MESSAGES });
                    });
            }).catch((error) => {
            dispatch({ type: ADD_POST_FAILED });
            message.warn("Failed to Save Post");
            console.log("job position creation failed");
            console.log(error);
            dispatch({ type: CLEAR_POST_MESSAGES });
        });
    }
};

export const clearPost = () => {
    return {
        type: CLEAR_POST
    }
}


export const sendInterviewOffer = ({ companyLocation, companyName, deadline, interviewDate, postTitle, selected, status, statusDate, offeredOn,
                                       withdraw, postID, userID, firstName, acceptedInterviews, rejectedInterviews, name, iasRating, interviewed, inviteComment,
                                       email, addToCalendar,selectedCandidate,post
}) => {

    return(dispatch) => {

        dispatch({type: SAVE_CANDIDATE });

        const slicedID = userID.slice(0, 5);
        const code = Math.random().toString(36).substring(6).toUpperCase();
        const interviewID = `${slicedID}${code}`;
        const companyID = firebase.auth().currentUser.uid;

        firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(userID)
            .set({
                acceptedInterviews,
                companyID,
                iasRating,
                interviewDate,
                interviewID,
                interviewed,
                inviteComment,
                name,
                rejectedInterviews,
                selected,
                status,
                statusDate,
                offeredOn,
                userID,
                withdraw,
                deadline
            })
            .then(() => {
                addCandidate({
                    companyLocation, companyName, companyID, deadline, interviewDate, interviewed, postTitle, selected,
                    status, statusDate, offeredOn, withdraw, interviewID, userID, firstName, postID, inviteComment, email,addToCalendar,selectedCandidate,post, dispatch
                });
            }).catch((error) => {
            message.warning("Failed to add candidate to post");
            console.log(error);
            dispatch({ type: SAVE_CANDIDATE_FAILED })
        });
    }
};

export const editInterviewOffer = ({ interviewID, updatedOn, interviewDate, inviteComment, postID, userID, firstName, companyName }) => {

    return(dispatch) => {

        dispatch({type: SAVE_CANDIDATE });

        firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(userID)
            .update({
                interviewDate,
                updatedOn,
                inviteComment,
            })
            .then(() => {
                firebase.firestore().collection("seekers").doc(userID).collection("interviews").doc(interviewID)
                    .update({
                        [`infoBox.interviewDate`]: interviewDate,
                        [`infoBox.inviteComment`]: inviteComment,
                        [`infoBox.updatedOn`]: updatedOn,
                    })
                    .then(() => {
                        message.success("Candidate updated successfully");
                        dispatch({ type: UPDATE_INTERVIEW_CANDIDATE_SUCCESSFUL});
                        sendNotification({
                            notificationTitle: "Interview Update",
                            notificationMessage: `Hi ${firstName}, your interview with ${companyName} has been updated`,
                            userID
                        }).catch(e => console.log(e));

                        firebase.firestore().collection("seekers").doc(userID)
                            .get()
                            .then(doc => {
                                if (doc.exists) {
                                    const seeker = doc.data();
                                    sendEmail({
                                        email: seeker.email,
                                        firstName,
                                        company: companyName,
                                        templateId: "d-d37ee952d7bf4d2d94cab8172cbd4fc4",
                                        deadline: new Date()
                                    }).catch(e => console.log(e));
                                }
                            })
                            .catch(e => console.log(e));
                    })
                    .catch(e => {
                        console.log(e);
                        dispatch({ type: SAVE_CANDIDATE_FAILED });
                    });
            }).catch((error) => {
            message.warning("Failed to add candidate to post");
            console.log(error);
            dispatch({ type: SAVE_CANDIDATE_FAILED})
        });
    }
};

const addCandidate = ({ companyLocation, companyName, companyID, deadline, interviewDate, interviewed, postTitle, selected,
                          status, statusDate, offeredOn, withdraw, interviewID, userID, firstName, postID, inviteComment, email,addToCalendar,selectedCandidate,post, dispatch }) => {

    const infoBox = {
        active: true,
        companyLocation,
        companyName,
        companyID,
        deadline,
        general: false,
        interviewDate,
        interviewed,
        postTitle,
        selected,
        status,
        statusDate,
        offeredOn,
        withdraw,
        interviewID,
        postID,
        inviteComment
    }

    firebase.firestore().collection("seekers").doc(userID).collection("interviews").doc(interviewID)
        .set({
            infoBox,
            adaptability: 0,
            analyticalThinking: 0,
            verbalCommunication: 0,
            writtenCommunication: 0,
            creativity: 0,
            teamwork: 0,
            leadership: 0,
            timeManagement: 0,
            organizationalSkills: 0,
            decisionMaking: 0,
            initiative: 0,
            workEthic: 0,
            professionalism: 0,
            emotionalIntelligence: 0
        })
        .then(() => {
            firebase.firestore().collection("users").doc("clients").collection(userID).doc("public").collection("account").doc("info")
                .update({ interviewRequests: firebase.firestore.FieldValue.increment(1) })
                .then(() => {
                    firebase.firestore().collection("seekers").doc(userID)
                        .update({ interviewRequests: firebase.firestore.FieldValue.increment(1) })
                        .then(() => {
                            firebase.firestore().collection("jobPosts").doc(postID)
                                .update({ interviewRequests: firebase.firestore.FieldValue.increment(1) })
                                .then(() => {
                                    message.success("Candidate added successfully");
                                    dispatch({ type: SAVE_CANDIDATE_SUCCESSFUL});
                                    sendNotification({
                                        notificationTitle: "New Interview Offer",
                                        notificationMessage: `Hi ${firstName}, please check your interviews section for a quick response to the offer you received from ${companyName}`,
                                        userID
                                    }).catch(e => console.log(e));

                                    sendEmail({
                                        email,
                                        firstName,
                                        company: companyName,
                                        deadline,
                                        templateId: "d-044c7e010c064799a137fc7a894b4730"
                                    }).catch(e => console.log(e));

                                    sendSMS({
                                        selectedCandidate,
                                        company: companyName,
                                    }).catch(e => console.log(e));

                                    sendWhatsAppMessage({
                                        selectedCandidate,
                                        company: companyName,
                                        deadline,
                                        
                                    }).catch(e => console.log(e));

                                 
                                    if (addToCalendar) {
                                        addEventToCalendar(selectedCandidate,interviewDate, post)      
                                    }
                                })
                                .catch((error) => {
                                    message.warning("Failed to update post sent offers");
                                    console.log(error);
                                    dispatch({ type: SAVE_CANDIDATE_FAILED })
                                });
                        })
                        .catch(e => console.log(e))
                })
                .catch(e => console.log(e))
        }).catch((error) => {
        message.warning("Failed to register interview to candidate");
        console.log(error);
        dispatch({ type: SAVE_CANDIDATE_FAILED });
    });
};

const sendNotification = async ({ notificationTitle, notificationMessage, userID }) => {
    try {
        const response = await fetch("https://us-central1-nafasiio.cloudfunctions.net/sendNotification", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notificationTitle,
                notificationMessage,
                userID,
            }),
        });

        if (response.ok) {
            //message.success("Notification sent successful to candidate!");
        } else {
            message.info("Interview offer is sent, however, could not send candidate a notification");
        }
    } catch (error) {
        console.error("Error calling function:", error);
    } finally {
        message.success("Notification sent successful to candidate!");
    }
};

const sendEmail = async ({ email, firstName, company, deadline, templateId }) => {
    const time = moment(deadline);
    const deadlineTime = time.format('DD MMM YYYY | HH:mm');

    try {
        const response = await fetch("https://us-central1-nafasiio.cloudfunctions.net/sendEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email, firstName, company, deadline: deadlineTime, templateId
            }),
        });

        if (response.ok) {
            //message.success("Email sent successful to candidate!");
        } else {
            message.info("Interview offer is sent, however, could not send candidate an email");
        }
    } catch (error) {
        console.error("Error calling function:", error);
    } finally {
        message.success("Email sent successful to candidate!");
    }
};

function formatPhoneNumber(number) {
    // Ensure the input is treated as a string
    let formattedNumber = number.toString();
  
    // Replace the leading zero with the country code 255
    formattedNumber = formattedNumber.replace(/^0/, '255');
  
    return formattedNumber;
  }


  const sendSMS = async ({ selectedCandidate, company }) => {

    const firstName = selectedCandidate.firstName; 
    const formattedPhone = formatPhoneNumber(selectedCandidate.phone);
    try {

        const response = await fetch('https://us-central1-nafasiio.cloudfunctions.net/sendSms', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({selectedCandidate, company ,formattedPhone}),
            
          });

          if (response.ok) {
            //message.success("Email sent successful to candidate!");
        } else {
            message.info("Interview offer is sent, however, could not send candidate SMS");
        }
    
    } catch (error) {
        console.error("Error calling function:", error);
    } finally {
        message.success("SMS sent successful to candidate!");
    }
};

const sendWhatsAppMessage = async ({ selectedCandidate, company, deadline }) => {
    const formattedPhone = formatPhoneNumber(selectedCandidate.phone);
    const firstName = selectedCandidate.firstName; 
    const time = moment(deadline);
    const deadlineTime = time.format('DD MMM YYYY | HH:mm');

    try {
        const response = await fetch('https://us-central1-nafasiio.cloudfunctions.net/sendWhatsAppMessage', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ selectedCandidate, company, deadlineTime,formattedPhone})
        });

        if (response.ok) {
            message.success("WhatsApp message sent successfully to the candidate!");
        } else {
            
            const errorDetails = await response.json();
            console.log(`HTTP error status: ${response.status} - ${JSON.stringify(errorDetails)}`);
            message.info("Interview offer is sent, however, could not send candidate a WhatsApp message");

        }
    } catch (error) {
        console.error("Error calling function:", error);
    }
};


export const addEventToCalendar = (selectedCandidate,interviewDate, post) =>{

        var gapi = window.gapi

        const interviewDateMoment = moment(interviewDate); // Convert to moment.js object
        const selectedDateTime = interviewDateMoment.format('YYYY-MM-DDTHH:mm:ss') + 'Z';

        const startDateTime = selectedDateTime; 
        const startDate = new Date(startDateTime);
        const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Add 1 hour to start date

        const endDateTimeString = endDate.toISOString(); // Format the end date back to a string

        gapi.load('client:auth2', () => {
            console.log('loaded client')

            gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
            plugin_name: 'hello' //any name can be used
            })
            .then(() => {
            const authInstance = gapi.auth2.getAuthInstance();
            if (authInstance.isSignedIn.get()) {
                // User is already signed in, proceed with adding the event
                addEvent(selectedCandidate,interviewDate, post,startDateTime, endDateTimeString)              
            } 
            else {
                authInstance.signIn()
                // User has signed in, proceed with adding the event
                .then(() => {addEvent(selectedCandidate,interviewDate, post,startDateTime, endDateTimeString) });  
            }
            });
        });


}

function addEvent(selectedCandidate,interviewDate, post,startDateTime, endDateTimeString){

    var event = {
        'summary': `${selectedCandidate.firstName} ${selectedCandidate.lastName}'s Interview For ${post.label} Position`,
        "start": {
            "dateTime": `${startDateTime}`, 
            "timeZone": "Africa/Nairobi" 
        },
        "end": {
            "dateTime": `${endDateTimeString}`, 
            "timeZone": "Africa/Nairobi" 
        },
        // 'recurrence': [
        //   'RRULE:FREQ=DAILY;COUNT=2'
        // ],
        'attendees': [
            {'email': `${selectedCandidate.email}`}
        ],
        'reminders': {
            'useDefault': false,
            'overrides': [
            {'method': 'popup', 'minutes': 10}
            ]
        }
        }

        var request = gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': event,
        })

        request.execute(event => {
        console.log('response',event)
        message.success("Added to calendar")
        // window.open(event.htmlLink)
        })
}


export const fetchPostCandidates = ({ postID }) => {

    return(dispatch) => {
        dispatch({ type: FETCH_POST_CANDIDATES });

        let fetchedCandidates = {};

        firebase.firestore().collection("jobPosts").doc(postID).collection("candidates")
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {
                    fetchedCandidates[doc.id] = doc.data();
                });
                dispatch({
                    type: FETCH_POST_CANDIDATES_SUCCESSFUL,
                    payload: fetchedCandidates
                });

            }, function (error) {
                console.log("Error fetching job opening candidates");
                console.log(error);
                dispatch({ type: FETCH_POST_CANDIDATES_FAILED })
            });
    }
};


export const deletePost = ({ postID }) => {

    return async (dispatch) => {
        dispatch({type: DELETE_JOB_POST});

        let successArray = [];
        try {
            const ref = firebase.firestore().collection("jobPosts").doc(postID).collection("candidates");
            const snapshot = await ref.get();
            //
            if (snapshot.size === 0) {
                //its empty
            } else {
                snapshot.forEach((doc => {
                    //delete doc
                    console.log({ postID, candidateID: doc.id });
                    const response = deleteCandidate({ postID , candidateID: doc.id });
                    successArray.push(response);
                }));
            }
        } catch (e) {
            console.log(e);
            console.log("job candidate deletion failed");
            dispatch({ type: CLEAR_POST_MESSAGES });
        }

        return Promise.all(successArray).then( async () => {
            dispatch({ type: DELETE_JOB_POST_SUCCESSFUL});
            dispatch({ type: CLEAR_POST_MESSAGES});

            try {
                return await firebase.firestore().collection("jobPosts").doc(postID).delete();
            } catch (e) {
                console.log(e);
                dispatch({ type: DELETE_JOB_POST_FAILED })
                console.log("job position deletion failed");
                console.log(e);
                dispatch({ type: CLEAR_POST_MESSAGES });
            }
        });
    }
};

async function deleteCandidate({ postID, candidateID }) {
    let successArray = [];
    try {
        const response  = await firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(candidateID).delete();
        successArray.push(response);
        const seekerSnapshot  = await firebase.firestore().collection("seekers").doc(candidateID).collection("interviews")
            .where("infoBox.postID", "==", postID)
            .where("infoBox.interviewed", "==", false)
            .get();
        if (!seekerSnapshot.empty) {
            seekerSnapshot.forEach(doc => {
                const seekerResponse = deleteCandidateInterview({ candidateID, interviewID: doc.id });
                successArray.push(seekerResponse);
            });
        } else {
        }
    } catch (e) {
        console.log(e);
        console.log(`error deleting candidate ${candidateID}`);
    }

    return Promise.all(successArray).then(() => {
        return true;
    })
}

async function deleteCandidateInterview({ candidateID, interviewID }) {
    let successArray = [];
    try {
        const response  = await firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc(interviewID).delete();
        successArray.push(response);
    } catch (e) {
        console.log(e);
        console.log(`error deleting candidate ${candidateID}`);
    }

    return Promise.all(successArray).then(() => {
        return true;
    })
}


export const withdrawCandidate = ({ interviewID, candidateID, postID, status }) => {
    return(dispatch) => {
        dispatch({type: WITHDRAW_CANDIDATE});
        firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc(interviewID)
            .update({ [`infoBox.withdraw`]: status })
            .then(() => {
                //
                firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(candidateID)
                    .update({ withdraw: status })
                    .then(() => {
                        dispatch({ type: WITHDRAW_CANDIDATE_SUCCESSFUL});
                        dispatch({ type: CLEAR_POST_MESSAGES});
                    })
                    .catch(error => {
                        dispatch({ type: WITHDRAW_CANDIDATE_FAILED })
                        console.log("job candidate deletion failed");
                        console.log(error);
                        dispatch({ type: CLEAR_POST_MESSAGES });
                    })
            }).catch((error) => {
            dispatch({ type: WITHDRAW_CANDIDATE_FAILED })
            console.log("job candidate deletion failed");
            console.log(error);
            dispatch({ type: CLEAR_POST_MESSAGES });
        });
    }
};


export const fetchShortlist = () => {

    return(dispatch) => {

        dispatch({ type: FETCH_SHORTLIST });

        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist")
            .orderBy("addedOn", "desc")
            .onSnapshot(snapshot => {

                if (snapshot.size === 0) {
                    dispatch({ type: FETCH_SHORTLIST_FAILED })
                } else {
                    let shortlist = [];

                    snapshot.forEach(function(doc) {
                        shortlist.push(doc.data());
                    });

                    dispatch({
                        type: FETCH_SHORTLIST_SUCCESSFUL,
                        payload: shortlist
                    });
                }

            }, function (error) {
                console.log("Error fetching shortlist");
                console.log(error);
                dispatch({ type: FETCH_SHORTLIST_FAILED })
            });
    }
};

export const pushSeeker = ({ pushedSeeker }) => {
    return {
        type: PUSHED_SEEKER,
        payload: pushedSeeker
    }
}

export const fetchSelectedSeeker = ({ seekerID }) => {

    return (dispatch) => {
        //
        dispatch({ type: FETCH_SEEKER });
        firebase.firestore().collection("seekers").doc(seekerID)
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const seeker = snapshot.data();
                    //
                    dispatch({
                        type: FETCH_SEEKER_SUCCESSFUL,
                        payload: seeker
                    });
                } else {
                    dispatch({ type: FETCH_SEEKER_FAILED });
                    message.warn("Candidate path does not exist");
                }
            }, function (error) {
                console.log(error);
                dispatch({ type: FETCH_SEEKER_FAILED });
                message.warn("Error occurred getting the candidate");
            });
    }
};

export const fetchSeekerProfile = ({ seekerID }) => {
    return(dispatch) => {
        //
        dispatch({ type: FETCH_SELECTED_CANDIDATE });
        firebase.firestore().collection("users").doc("clients").collection(seekerID).doc("public").collection("account").doc("info")
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const seeker = snapshot.data();
                    dispatch({
                        type: FETCH_SELECTED_CANDIDATE_SUCCESSFUL,
                        payload: seeker
                    });
                } else {
                    dispatch({ type: FETCH_SELECTED_CANDIDATE_FAILED });
                }
            }, function (error) {
                console.log(error);
                dispatch({ type: FETCH_SELECTED_CANDIDATE_FAILED });
            })
    }
}


export const fetchSeekerPath = ({ seekerID, path }) => {
    //certificates, education, employment, languages, awards,
    //licenses, military, skills, links, interviews
    return async (dispatch) => {
        dispatch({
            type: FETCH_SEEKER_PATH,
            payload: path
        });

        if (path === "interviews") {
            const generalInterviewQuerySnapshot = await firebase.firestore()
                .collection("seekers")
                .doc(seekerID)
                .collection("interviews")
                .doc("general")
                .get();

            const interviewsQuerySnapshot = await firebase.firestore()
                .collection("seekers")
                .doc(seekerID)
                .collection("interviews")
                .where("infoBox.interviewed", "==", true)
                .where("infoBox.withdraw", "==", false)
                .orderBy("infoBox.interviewDate", "desc")
                .limit(5)
                .get();

            const userID = firebase.auth().currentUser.uid;
            const myInterviewQuerySnapshot = await firebase.firestore()
                .collection("seekers")
                .doc(seekerID)
                .collection("interviews")
                .where("infoBox.companyID", "==", userID)
                .where("infoBox.withdraw", "==", false)
                .get();

            const interviews = interviewsQuerySnapshot.docs.map((doc) => doc.data());
            let generalInterview = null;
            if (generalInterviewQuerySnapshot.exists) {
                generalInterview = generalInterviewQuerySnapshot.data();
            }

            const mergedInterviews = interviews.concat(generalInterview || []);
            const myInterviews = myInterviewQuerySnapshot.docs.map((doc) => doc.data());

            dispatch({
                type: FETCH_SEEKER_PATH_SUCCESSFUL,
                payload: { interviews: mergedInterviews, myInterviews, path }
            });

        } else {
            firebase.firestore()
                .collection("seekers")
                .doc(seekerID)
                .collection(path)
                .onSnapshot(snapshot => {
                    if (snapshot.size === 0) {
                        dispatch({
                            type: FETCH_SEEKER_PATH_FAILED,
                            payload: path
                        });
                    } else {
                        let response = [];
                        snapshot.forEach(doc => {
                            response.push(doc.data());
                        });

                        dispatch({
                            type: FETCH_SEEKER_PATH_SUCCESSFUL,
                            payload: { response, path }
                        });
                    }
                }, function (error) {
                    console.log(error);
                    dispatch({
                        type: FETCH_SEEKER_PATH_FAILED,
                        payload: path
                    });
                });
        }
    }
}


export const fetchVettingResult = (item) => {
    return (dispatch) => {
        let vettingResult = {};
        dispatch({type: FETCH_VETTING_RESULT});
        firebase.firestore().collection('seekers').doc(item.userID).collection('vets').doc(item.refereeID)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    vettingResult = doc.data();
                }

                dispatch({type: FETCH_VETTING_RESULT_SUCCESS, payload: vettingResult});

            }).catch((e) => {
                console.log(e);
                dispatch({type: FETCH_VETTING_RESULT_FAIL});
            })
    }
}

async function base64UploadFile({ base64Data, fileType, path }) {
    try {
        const blob = base64ToBlob(base64Data, fileType);

        const response = await fetch(
            `https://us-central1-nafasiio.cloudfunctions.net/upload${path}`,
            {
                method: "POST",
                mode: "cors",
                headers: { "Content-Type": fileType },
                body: blob,
            }
        );

        return await response.text();
    } catch (error) {
        console.error("Here's your error");
        console.error(error);

        throw error;
    }
}

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const saveRatings = ({ adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
                                organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication,
                                candidateID, interviewID, postID, firstName, postTitle, companyName }) => {
    return(dispatch) => {
        dispatch({ type: SAVE_RATINGS });
        firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc(interviewID)
            .update({
                [`infoBox.interviewed`]: true,
                adaptability, analyticalThinking, creativity, decisionMaking, emotionalIntelligence, initiative, leadership,
                organizationalSkills, professionalism, teamwork, timeManagement, verbalCommunication, workEthic, writtenCommunication
            })
            .then(() => {
                firebase.firestore().collection("seekers").doc(candidateID).collection("interviews")
                    .where("infoBox.interviewed", "==", true)
                    .get()
                    .then(snapshot => {
                        if (snapshot.size === 0) {
                            //
                        } else {
                            let adaptabilityArray = [];
                            let analyticalThinkingArray = [];
                            let creativityArray = [];
                            let decisionMakingArray = [];
                            let emotionalIntelligenceArray = [];
                            let initiativeArray = [];
                            let leadershipArray = [];
                            let organizationalSkillsArray = [];
                            let professionalismArray = [];
                            let teamworkArray = [];
                            let timeManagementArray = [];
                            let verbalCommunicationArray = [];
                            let workEthicArray = [];
                            let writtenCommunicationArray = [];

                            snapshot.forEach(doc => {
                                const interview = doc.data();
                                if (!interview.infoBox.general) {
                                    adaptabilityArray.push(interview.adaptability);
                                    analyticalThinkingArray.push(interview.analyticalThinking);
                                    creativityArray.push(interview.creativity);
                                    decisionMakingArray.push(interview.decisionMaking);
                                    emotionalIntelligenceArray.push(interview.emotionalIntelligence);
                                    initiativeArray.push(interview.initiative);
                                    leadershipArray.push(interview.leadership);
                                    organizationalSkillsArray.push(interview.organizationalSkills);
                                    professionalismArray.push(interview.professionalism);
                                    teamworkArray.push(interview.teamwork);
                                    timeManagementArray.push(interview.timeManagement);
                                    verbalCommunicationArray.push(interview.verbalCommunication);
                                    workEthicArray.push(interview.workEthic);
                                    writtenCommunicationArray.push(interview.writtenCommunication);
                                }
                            });

                            const size = snapshot.size - 1;

                            const adaptabilityValue = (adaptabilityArray.reduce((a, b) => a + b, 0)) / size;
                            const analyticalThinkingValue = (analyticalThinkingArray.reduce((a, b) => a + b, 0)) / size;
                            const creativityValue = (creativityArray.reduce((a, b) => a + b, 0)) / size;
                            const decisionMakingValue = (decisionMakingArray.reduce((a, b) => a + b, 0)) / size;
                            const emotionalIntelligenceValue = (emotionalIntelligenceArray.reduce((a, b) => a + b, 0)) / size;
                            const initiativeValue = (initiativeArray.reduce((a, b) => a + b, 0)) / size;
                            const leadershipValue = (leadershipArray.reduce((a, b) => a + b, 0)) / size;
                            const organizationalSkillsValue = (organizationalSkillsArray.reduce((a, b) => a + b, 0)) / size;
                            const professionalismValue = (professionalismArray.reduce((a, b) => a + b, 0)) / size;
                            const teamworkValue = (teamworkArray.reduce((a, b) => a + b, 0)) / size;
                            const timeManagementValue = (timeManagementArray.reduce((a, b) => a + b, 0)) / size;
                            const verbalCommunicationValue = (verbalCommunicationArray.reduce((a, b) => a + b, 0)) / size;
                            const workEthicValue = (workEthicArray.reduce((a, b) => a + b, 0)) / size;
                            const writtenCommunicationValue = (writtenCommunicationArray.reduce((a, b) => a + b, 0)) / size;

                            firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc("general")
                                .update({ adaptability: adaptabilityValue, analyticalThinking: analyticalThinkingValue, creativity: creativityValue, decisionMaking: decisionMakingValue, emotionalIntelligence: emotionalIntelligenceValue, initiative: initiativeValue, leadership: leadershipValue,
                                    organizationalSkills: organizationalSkillsValue, professionalism: professionalismValue, teamwork: teamworkValue, timeManagement: timeManagementValue, verbalCommunication: verbalCommunicationValue, workEthic: workEthicValue, writtenCommunication: writtenCommunicationValue })
                                .then(() => {
                                    firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(candidateID)
                                        .update({ interviewed: true, iasRating: true, iasRatingDate: new Date() })
                                        .then(() => {
                                            dispatch({ type: SAVE_RATINGS_SUCCESSFUL });
                                            message.success("Ratings saved successfully");
                                            sendNotification({
                                                notificationTitle: "New Interview Ratings",
                                                notificationMessage: `Hi ${firstName}, your ${postTitle} ratings from ${companyName} are in!`,
                                                userID: candidateID
                                            }).catch(e => console.log(e));
                                            setCandidateShowUpStatus(candidateID,interviewID)
                                            .then(() => {
                                                console.log('Candidate Show Up Status set');
                                                // Call updateShowUpRate after setCandidateShowUpStatus completes
                                                return updateShowUpRate(candidateID);
                                            })
                                            .then(() => {
                                                console.log('Show Up Rate Updated');
                                            })
                                            .catch(e => {
                                                console.error('Error updating Show Up Rate:', e);
                                            });
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            dispatch({ type: SAVE_RATINGS_FAILED });
                                            message.warn("Failed to update job post");
                                        })
                                })
                                .catch(e => {
                                    console.log(e);
                                    dispatch({ type: SAVE_RATINGS_FAILED });
                                    message.success("Failed to update candidates general ratings");
                                })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        dispatch({ type: SAVE_RATINGS_FAILED });
                        message.success("Failed to get candidates interviews");
                    });
            })
            .catch((e) => {
                console.log(e);
                dispatch({ type: SAVE_RATINGS_FAILED });
                message.success("Failed to update interview ratings");
            });
    };
}


function setCandidateShowUpStatus(candidateID, interviewID) {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("seekers").doc(candidateID).collection("interviews").doc(interviewID)
            .update({ "infoBox.showedUp": true })
            .then(() => {
                console.log("Document successfully updated with showedUp status.");
                resolve(); // Resolve the promise here
            })
            .catch(error => {
                console.error("Error updating document with showedUp status:", error);
                reject(error); // Reject the promise here
            });
    });
}

async function updateShowUpRate(candidateID) {
    try {
        const interviewsRef = firebase.firestore().collection("seekers").doc(candidateID).collection("interviews");
        const showUpQuery = interviewsRef.where("infoBox.showedUp", "==", true);
        const showUpSnapshot = await showUpQuery.get();
        const showUpCount = showUpSnapshot.size;

        const candidateDocRef = firebase.firestore().collection("seekers").doc(candidateID);
        const candidateDocSnapshot = await candidateDocRef.get();
        if (!candidateDocSnapshot.exists) {
            console.error("Candidate document does not exist.");
            return;
        }

        const acceptedInterviews = candidateDocSnapshot.data().acceptedInterviews;
        if (acceptedInterviews <= 0) {
            console.error("No accepted interviews found.");
            return;
        }

        const showUpRate = Number(((showUpCount / acceptedInterviews) * 100).toFixed(1));
        console.log('showup', showUpRate);
        await candidateDocRef.set({ showUpRate: showUpRate }, { merge: true });

        console.log("Document successfully updated with show-up rate.");
    } catch (error) {
        console.error("Error updating document with show-up rate:", error);
    }
}


export const candidateSelection = ({  firstName, postTitle, companyName, selected, selectionDate, postID, userID, selectionComment }) => {

    return(dispatch) => {
        dispatch({type: CANDIDATE_SELECTION });

        firebase.firestore().collection("jobPosts").doc(postID).collection("candidates").doc(userID)
            .update({
                selected,
                selectionDate,
                selectionComment
            })
            .then(() => {
                firebase.firestore().collection("seekers").doc(userID).collection("interviews").where(`infoBox.postID`, "==", postID)
                    .get()
                    .then(snapshot => {
                        if (snapshot.size === 0) {
                            dispatch({ type: CANDIDATE_SELECTION_FAILED });
                        } else {
                            let interview = {};
                            snapshot.forEach(doc => {
                                interview = doc.data();
                            });

                            if (!(_.isEmpty(interview))) {
                                updateSelectedCandidate({
                                    firstName, postTitle, companyName, selected, selectionDate, postID,  userID, selectionComment, interviewID: interview.infoBox.interviewID, dispatch
                                });
                            } else {
                                dispatch({ type: CANDIDATE_SELECTION_FAILED });
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        dispatch({ type: CANDIDATE_SELECTION_FAILED })
                    })
            }).catch((error) => {
            message.warning("Failed to add candidate to post");
            console.log(error);
            dispatch({ type: CANDIDATE_SELECTION_FAILED })
        });
    }
};

const updateSelectedCandidate = ({ firstName, postTitle, companyName, selected, selectionDate, postID,  userID, selectionComment, interviewID, dispatch }) => {

    const update = {
        [`infoBox.selected`]: selected,
        [`infoBox.selectionDate`]: selectionDate,
        [`infoBox.selectionComment`]: selectionComment,
    }

    let selectUpdate = {
        selectedCandidates: firebase.firestore.FieldValue.increment(1)
    }

    if (!selected) {
        selectUpdate = {
            unsuccessfulCandidates: firebase.firestore.FieldValue.increment(1)
        }
    }

    firebase.firestore().collection("seekers").doc(userID).collection("interviews").doc(interviewID)
        .update(update)
        .then(() => {
            firebase.firestore().collection("users").doc("clients").collection(userID).doc("public").collection("account").doc("info")
                .update(selectUpdate)
                .then(() => {
                    firebase.firestore().collection("seekers").doc(userID)
                        .update(selectUpdate)
                        .then(() => {
                            firebase.firestore().collection("jobPosts").doc(postID)
                                .update(selectUpdate)
                                .then(() => {
                                    message.success("Candidate Selection Done Successfully");
                                    dispatch({ type: CANDIDATE_SELECTION_SUCCESSFUL});
                                    let title = "You have been selected!";
                                    let messageBody = `Congratulations ${firstName}, You've been selected for the ${postTitle} vacancy at ${companyName}.`;

                                    if (!selected) {
                                        title = "Interview Status Update";
                                        messageBody = `Hi ${firstName}, Thank you for your interest in the ${postTitle} vacancy but ${companyName} regrets to inform you that your interview was unsuccessful. We're currently sending your CV to other companies, sit tight!`;
                                    }

                                    sendNotification({
                                        notificationTitle: title,
                                        notificationMessage: messageBody,
                                        userID
                                    }).catch(e => console.log(e));
                                })
                                .catch((error) => {
                                    message.warning("Failed to select candidate");
                                    console.log(error);
                                    dispatch({ type: CANDIDATE_SELECTION_FAILED })
                                });
                        })
                        .catch(e => console.log(e))
                })
                .catch(e => console.log(e))
        }).catch((error) => {
        message.warning("Failed to select candidate");
        console.log(error);
        dispatch({ type: CANDIDATE_SELECTION_FAILED });
    });
};

export const fetchCandidatesYearOfExperience = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_CANDIDATES_EXPERIENCE});
            const ref = firebase.firestore().collection("seekers");
            const snapshot = await ref.get();
    
            if (snapshot.size !== 0) {

                //fetch candidatesExperiences
                const promises = snapshot.docs.map(async (doc) => {
                    const candidateID = doc.id;
                    return fetchCandidateJobExperience(candidateID);
                });

                let successArray = await Promise.all(promises);
                successArray = successArray.filter(experience => experience!== undefined);

                let obj = {};
                successArray.forEach(per => {
                    if (per.years in obj) {
                        obj[per.years].number++;
                    } else {
                        obj[per.years] = {
                            years: per.years,
                            number: 1
                        }
                    }
                })

                //fetch candidates educations
                const educationPromises = snapshot.docs.map(async (doc) => {
                    const candidateID = doc.id;
                    return fetchCandidateJobEducation(candidateID);
                });

                let educationArray = await Promise.all(educationPromises);
                educationArray = educationArray.filter(experience => experience!== undefined);

                let educationObj = {};
                educationArray.forEach(per => {
                    if (per.educationLevelID in educationObj) {
                        educationObj[per.educationLevelID].number++;
                    } else {
                        educationObj[per.educationLevelID] = {
                            educationLevelID: per.educationLevelID,
                            educationLevelTitle: per.educationLevelTitle,
                            number: 1
                        }
                    }
                })


                const candidateExperience = Object.values(obj);
                const candidateLevel = Object.values(educationObj);

                dispatch({type: FETCH_CANDIDATES_EXPERIENCE_SUCCESS, payload: {candidateExperience, candidateLevel}});

            } else {
                dispatch({type: FETCH_CANDIDATES_EXPERIENCE_FAIL});
            }
        } catch (e) {
            dispatch({type: FETCH_CANDIDATES_EXPERIENCE_FAIL});
            console.log(e);
        }
    }
}

async function fetchCandidateJobExperience(candidateID) {
    try {
        const ref = firebase.firestore().collection("seekers").doc(candidateID).collection("employment");
        const snapshot = await ref.get();

        if (snapshot.size !== 0) {
            let months = 0;
            snapshot.forEach(doc => {
                const job = doc.data();
        
                let seconds;
                job.timeOfWorkFrom.seconds? seconds = job.timeOfWorkFrom.seconds : seconds = job.timeOfWorkFrom._seconds;
                const timeOfWorkFrom = moment.unix(seconds);
        
                if (job.currentWork) {
                    const now = moment();
                    months += now.diff(timeOfWorkFrom, 'months');
                } else {
                    let seconds1;
                    job.timeOfWorkTo.seconds? seconds1 = job.timeOfWorkTo.seconds : seconds1 = job.timeOfWorkTo._seconds;
                    const timeOfWorkTo = moment.unix(seconds1);
                    months += timeOfWorkTo.diff(timeOfWorkFrom, 'months');
                }
            });

            return { years: Math.round(months / 12), candidateID: candidateID }
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}


async function fetchCandidateJobEducation(candidateID) {
    try {
        const ref = firebase.firestore().collection("seekers").doc(candidateID).collection("education");
        const snapshot = await ref.get();

        if (snapshot.size !== 0) {
            let obj = {};
            snapshot.forEach(doc => {
                const job = doc.data();
                obj = {educationLevelID: job.educationLevel.industryID, educationLevelTitle: job.educationLevel.title};
            });

            return {...obj }
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export const fetchUpcomingInterviews = (item) => {
    return (dispatch) => {
        dispatch({ type: FETCH_UPCOMING_INTERVIEWS });

        const companyID = firebase.auth().currentUser.uid;
        let jobPosts = [];
        let candidates = [];

        firebase.firestore().collection('jobPosts').where("companyID", "==", companyID)
            .onSnapshot(jobPostSnapshot => {
                jobPostSnapshot.forEach(doc => {
                    jobPosts.push({ id: doc.id, name: doc.data().postTitle });
                });
                console.log('posts', jobPosts);

                // Use Promise.all to wait for all candidate queries to complete
                Promise.all(jobPosts.map(jobPost => 
                    firebase.firestore().collection('jobPosts').doc(jobPost.id).collection("candidates").where("interviewed", "==", false)
                    .orderBy("interviewDate", "desc")
                    .limit(5)
                    .get()
                ))
                .then(querySnapshots => {
                    querySnapshots.forEach((snapshot, index) => {
                        snapshot.forEach(candidateDoc => {
                            let candidateData = candidateDoc.data();
                            candidateData.jobPostName = jobPosts[index].name;
                            candidates.push(candidateData);
                        });
                    });
                    console.log('candidates', candidates.slice(0, 5));
                    const slicedCandidates = candidates.slice(0, 5);
                    dispatch({ type: FETCHED_UPCOMING_INTERVIEWS_SUCESSFULLY, payload: slicedCandidates });
                })
                .catch(error => {
                    console.error("Error fetching candidates from job posts: ", error);
                    dispatch({ type: FETCH_UPCOMING_INTERVIEWS_FAILED });
                });
            });
    };
};

