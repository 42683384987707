import React from 'react';
import { connect } from "react-redux";
import firebase from "firebase";
import {isMobile} from 'react-device-detect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faAddressBook,
    faBell,
    faBirthdayCake,
    faBriefcase,
    faBriefcaseMedical,
    faCalculator,
    faCheckSquare,
    faChevronCircleDown,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClock,
    faCog,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faFile,
    faFileSignature,
    faFolder,
    faGlassCheers,
    faGraduationCap,
    faHashtag,
    faHeart,
    faHeartbeat,
    faHistory,
    faIdCard,
    faLink,
    faListAlt,
    faMapMarkerAlt,
    faPhone,
    faSignOutAlt,
    faStopwatch,
    faTasks,
    faTimes,
    faToolbox,
    faUmbrellaBeach,
    faUserCircle,
    faUserCheck,
    faUsers,
    faCreditCard,
    faExclamationTriangle,
    faTicketAlt,
    faLockOpen,
    faLock,
    faTrash,
    faLongArrowAltRight,
    faSpinner,
    faBullhorn,
    faSmile,
    faEyeSlash,
    faUserEdit,
    faFileUpload,
    faCaretRight,
    faCheckCircle,
    faTimesCircle,
    faBaby,
    faLaptop,
    faDesktop,
    faMobile,
    faUserPlus,
    faPlus,
    faUserCog,
    faUser,
    faPrint,
    faMinus,
    faPhoneAlt,
    faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

//css import
import './App.css';
import './ResponsiveApp.css';
import './styles.css';

//-> spinner imports
import { BeatLoader } from "react-spinners";

//action imports
import { fetchMyProfile } from "./actions/ProfileActions";
import { fetchNotifications } from './actions/NotificationsActions';
import {userLogOut} from "./actions/AuthenticationActions";
import { fetchRecentSearches } from "./actions/HomeActions";
import { fetchPosts, fetchShortlist, fetchAdminPosts } from "./actions/PostsActions";
import { fetchAssets, fetchTermsAndConditions, fetchFeedback, fetchAssessment, fetchRecruitersRequests } from "./actions/SettingsActions";
import { fetchSystemInfo } from "./actions/SystemActions";
import {fetchMonths, fetchPayments} from "./actions/SubscriptionActions";
import { fetchUpcomingInterviews } from './actions/PostsActions';

//-> component imports
import Main from './components/Main';
import Landing from './components/Landing';
import IsMobile from './components/IsMobile';

//-> fontawesome icon library
library.add(
    fab,
    faUserCircle,
    faCog,
    faBell,
    faChevronLeft,
    faChevronRight,
    faBriefcaseMedical,
    faUmbrellaBeach,
    faStopwatch,
    faTimes,
    faChevronCircleDown,
    faGraduationCap,
    faBirthdayCake,
    faLink,
    faIdCard,
    faBriefcase,
    faCircle,
    faClock,
    faUsers,
    faHistory,
    faPhone,
    faEnvelope,
    faHashtag,
    faMapMarkerAlt,
    faFile,
    faFolder,
    faCalculator,
    faSignOutAlt,
    faHeart,
    faHeartbeat,
    faGraduationCap,
    faListAlt,
    faCheckSquare,
    faAddressBook,
    faEye,
    faGlassCheers,
    faFileSignature,
    faExclamationCircle,
    faTasks,
    faToolbox,
    faCreditCard,
    faExclamationTriangle,
    faTicketAlt,
    faLockOpen,
    faLock,
    faTrash,
    faLongArrowAltRight,
    faSpinner,
    faBullhorn,
    faSmile,
    faEyeSlash,
    faUserEdit,
    faFileUpload,
    faCaretRight,
    faCheckCircle,
    faTimesCircle,
    faBaby,
    faLaptop,
    faDesktop,
    faMobile,
    faUserPlus,
    faPlus,
    faUserCog,
    faUser,
    faPrint,
    faMinus,
    faPhoneAlt,
    faCaretDown,
    faUserCheck
);


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            notificationFetchLimit: this.props.notificationFetchLimit,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            systemInfoIsEmpty: this.props.systemInfoIsEmpty,
        };

        this.renderComponent = this.renderComponent.bind(this);
    }

    componentDidMount() {
        //extract state
        const { notificationFetchLimit } = this.state;

        const firebaseConfig = {
            apiKey: "AIzaSyDJugXJ593M37xMb_6FuxUPEZ-boG0Nsow",
            authDomain: "nafasiio.firebaseapp.com",
            projectId: "nafasiio",
            storageBucket: "nafasiio.appspot.com",
            messagingSenderId: "849321740104",
            appId: "1:849321740104:web:f898d624aa65370591d57d",
            measurementId: "G-LN0267W1TL"
          };

        if (!firebase.apps.length) {
            // Initialize Firebase app if it hasn't been initialized yet
            firebase.initializeApp(firebaseConfig);
        }
        //set general initialization to redux
        // firebase.initializeApp(firebaseConfig);

        this.props.fetchTermsAndConditions();

        //check if a user has logged in
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                // user has logged in
                this.setState({ user: true });
                this.props.fetchMyProfile();
                this.props.fetchSystemInfo();
                this.props.fetchMonths();
                this.props.fetchPayments();
                this.props.fetchRecentSearches();
                this.props.fetchPosts();
                this.props.fetchShortlist();
                this.props.fetchAssets();
                this.props.fetchFeedback();
                this.props.fetchRecruitersRequests();
                this.props.fetchAssessment();
                this.props.fetchUpcomingInterviews();
                this.props.fetchNotifications({ notificationFetchLimit });
                // this.props.fetchAdminPosts();
            } else {
                this.setState({ user: false });
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }

        if (nextProps.systemInfoIsEmpty !== this.props.systemInfoIsEmpty) {
            this.setState({ systemInfoIsEmpty: nextProps.systemInfoIsEmpty });
        }
    }

    renderMain(){
        if(isMobile){
            return (<IsMobile/>);
        } else {
            return (<Main/>);
        }
    }

    renderComponent() {
        switch (this.state.user) {
            case true:
                return (
                    <div>
                        {this.renderMain()}
                    </div>
                );
            case false:
                return(
                    <div>
                        <Landing/>
                    </div>
                );
            default:
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50vh" }}>
                        <BeatLoader/>
                    </div>
                );
        }
    }

    render(){

        console.log("Profile", this.state.profile)
        return (
            <div id={'outer-container'}>
                { this.renderComponent() }
            </div>
        );
    }
}

const mapStateToProps = state => {

    const { notificationFetchLimit } = state.notifications;
    const { profile } = state.profile;
    const { systemInfo, systemInfoIsEmpty } = state.system;

    return {

        notificationFetchLimit,
        profile,
        systemInfo,
        systemInfoIsEmpty,
    }

    
};

export default connect(mapStateToProps, { fetchMyProfile, userLogOut, fetchNotifications,
    fetchSystemInfo, fetchMonths, fetchPayments, fetchAssets, fetchTermsAndConditions, fetchRecentSearches,
    fetchPosts, fetchShortlist, fetchAdminPosts, fetchFeedback, fetchAssessment, fetchRecruitersRequests, fetchUpcomingInterviews})(App);
