import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import _ from "lodash";
import { message, Input, Select, Popconfirm } from "antd";
import { HeartOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';

//action imports
import { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment, validateAffiliateCode } from "../../../../actions/SubscriptionActions";

//component imports

//file imports
//import Logo from '../../../../education/logos/logo.png';
import Earth from '../../../../assets/images/worldwide.png';
import Star from '../../../../assets/images/star.png';
import Galaxy from '../../../../assets/images/solar-system.png';
import Universe from '../../../../assets/images/universe.png';
import {Spinner} from "../../../common/Spinner";
import PaymentModal from "./PaymentModal";

//property declaration
const paymentPackages = [
    {
        benefits: [],
        packageID: "individual",
        packageIndex: 4,
        price: 99000,
        foreignPrice: 40,
        title: "Individual",
        index: 4,
        className: "universe",
        icon: Earth
    },
    {
        benefits: [],
        packageID: "startup",
        packageIndex: 3,
        price: 159000,
        foreignPrice: 59,
        title: "Startup",
        index: 3,
        className: "galaxy",
        icon: Star
    },
    {
        benefits: [],
        packageID: "business",
        packageIndex: 2,
        price: 257000,
        foreignPrice: 99,
        title: "Business",
        index: 2,
        className: "universe",
        icon: Galaxy
    },
    {
        benefits: [],
        packageID: "enterprise",
        packageIndex: 1,
        price: 699000,
        foreignPrice: 269,
        title: "Enterprise",
        index: 1,
        className: "galaxy",
        icon: Universe
    }
];



class ChoosePackageBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            systemInfo: this.props.systemInfo,
            profile: this.props.profile,

            paymentPackage: this.props.paymentPackage,
            carrier: this.props.carrier,
            paymentPhone: this.props.paymentPhone,
            paymentMonths: this.props.paymentMonths,

            months: this.props.months,
            paymentPackages: this.props.paymentPackages,
            paymentLoading: this.props.paymentLoading,
            transID: this.props.transID,
            payment: this.props.payment,

            affiliateCode: this.props.affiliateCode,
            validCode: this.props.validCode,
            validCodeLoading: this.props.validCodeLoading,
            success: this.props.success,

            subTotal: 0,
            discount: 0,
            discountPercent: 0,
            processingFee: 0,
            total: 0,

            flwUrl: this.props.flwUrl,
            packageTitle: this.props.packageTitle
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handlePaymentPackageChange = this.handlePaymentPackageChange.bind(this);
        this.handleSelectCarrier = this.handleSelectCarrier.bind(this);
        this.handleSelectMonth = this.handleSelectMonth.bind(this);
        this.onMakePayment = this.onMakePayment.bind(this);
        this.populatePhone = this.populatePhone.bind(this);
        this.handleAffiliateCode = this.handleAffiliateCode.bind(this);
        this.onApplyPromo = this.onApplyPromo.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.populatePhone();
        this.populateTransID();
        this.onAlgorithm({ paymentPackage: this.state.paymentPackage, paymentMonths: this.props.paymentMonths, validCode: this.props.validCode })
    }

    componentWillUnmount() {
        this.props.subscriptionFieldUpdated({prop: 'affiliateCode', value: "" });
        this.props.subscriptionFieldUpdated({prop: 'validCode', value: {} });
        this.props.subscriptionFieldUpdated({prop: 'validCodeLoading', value: false });
        this.props.subscriptionFieldUpdated({prop: 'success', value: null });
    }

    populatePhone() {
        const { profile } = this.state;
        if (!(_.isEmpty(profile))) {
            const phone = profile.phone;
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value: phone });
        }
    }

    populateTransID() {
        const { transID } = this.state;
        if (transID) {
            this.props.fetchPaymentStatus({ transactionID: transID });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                systemInfo: nextProps.systemInfo,
                profile: nextProps.profile,

                paymentPackage: nextProps.paymentPackage,
                carrier: nextProps.carrier,
                paymentPhone: nextProps.paymentPhone,
                paymentMonths: nextProps.paymentMonths,

                months: nextProps.months,
                packageTitle: nextProps.packageTitle,
                paymentPackages: nextProps.paymentPackages,
                paymentLoading: nextProps.paymentLoading,
                transID: nextProps.transID,
                payment: nextProps.payment,
                flwUrl: nextProps.flwUrl,

                affiliateCode: nextProps.affiliateCode,
                validCode: nextProps.validCode,
                validCodeLoading: nextProps.validCodeLoading,
                success: nextProps.success,

                subTotal: nextProps.subTotal,
                discount: nextProps.discount,
                discountPercent: nextProps.discountPercent,
                processingFee: nextProps.processingFee,
                total: nextProps.total,
            });
        }

        if (nextProps.flwUrl !== this.props.flwUrl) {
            console.log({ flwUrl: nextProps.flwUrl });
        }

        if (nextProps.profile !== this.props.profile) {
            this.populatePhone();
        }

        if (nextProps.transID !== this.props.transID) {
            this.populateTransID();
        }

        this.onAlgorithm({ paymentPackage: nextProps.paymentPackage, paymentMonths: nextProps.paymentMonths, validCode: nextProps.validCode })
    }

    onApplyPromo (){
        try {
            const { affiliateCode } = this.state;
            console.log(affiliateCode);
            if (affiliateCode && affiliateCode.length > 4) {
                this.props.validateAffiliateCode({ affiliateCode });
            } else {
                alert("Please enter a valid code");
            }
        } catch (e) {
            console.log(e);
        }
    }

    onAlgorithm({ paymentPackage, paymentMonths, validCode }) {
        let subTotal = 0;
        let discountPercent = 0;
        let discount = 0;
        let processingFee = 0;
        const app = this.state.systemInfo || {}; // Assuming `app` is a prop

        if (!(_.isEmpty(paymentPackage))) {
            subTotal = paymentPackage.price;
        }
        if (!(_.isEmpty(paymentMonths))) {
            const payMonth = JSON.parse(paymentMonths);
            subTotal = subTotal * payMonth.value;
        }

        //COMPUTE AFFILIATE CODE
        if (!(_.isEmpty(validCode))) {
            try {
                //check if valid code has a discount percent
                if ("discountPercent" in validCode) {
                    discountPercent = validCode.discountPercent;
                    if (discountPercent > 0) {
                        discount  = subTotal * discountPercent;
                    } else {
                        if ("discountPercent" in app) {
                            discountPercent = app.discountPercent;
                            discount  = subTotal * discountPercent;
                        }
                    }
                } else {
                    if ("discountPercent" in app) {
                        discountPercent = app.discountPercent;
                        discount  = subTotal * discountPercent;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }


        if ("processingFee" in app) {
            const processing = app.processingFee;
            processingFee = subTotal * processing;
        }

        const total = (subTotal + processingFee) - discount;

        this.props.subscriptionFieldUpdated({ prop: "discount", value: discount });
        this.props.subscriptionFieldUpdated({ prop: "discountPercent", value: discountPercent });
        this.props.subscriptionFieldUpdated({ prop: "subTotal", value: subTotal });
        this.props.subscriptionFieldUpdated({ prop: "processingFee", value: processingFee });
        this.props.subscriptionFieldUpdated({ prop: "total", value: total });
    }

    handlePhoneChange(e) {
        const value = e.target.value;

        if (value.length === 0) {
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
        } else {
            const validateInput = (input) => {
                const regex = /^\d+$/; // Regex to match only digits
                const maxLength = 10;
                return regex.test(input) && input.length <= maxLength;
            };

            if (validateInput(value)) {
                this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
            } else {
                message.warning("Enter a valid phone number");
            }
        }
    };

    handleAffiliateCode(e) {
        const value = e.target.value;
        this.props.subscriptionFieldUpdated({prop: 'affiliateCode', value: value.toUpperCase() });
        this.props.subscriptionFieldUpdated({prop: 'success', value: null} );
        this.props.subscriptionFieldUpdated({prop: 'validCode', value: {} });
    };

    handlePaymentPackageChange(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentPackage", value });
    };

    handleSelectCarrier(value) {
        this.props.subscriptionFieldUpdated({ prop: "carrier", value });
    };

    handleSelectMonth(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentMonths", value });
    };

    onMakePayment(paymentPackage) {
        const { profile, carrier, paymentMonths, paymentPhone, validCode, systemInfo } = this.state;

        try {
            if (paymentPackage.index === null) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    const name = `${profile.firstName} ${profile.lastName}`;
                    const phone = profile.phone;
                    const email = profile.email;

                    let location = null;
                    if ("companyLocation" in profile) {
                        location = profile.companyLocation;
                    }

                    const paymentDate = new Date();

                    if (!(_.isEmpty(paymentPackage))) {
                        if (!(_.isEmpty(carrier))) {
                            if (!(_.isEmpty(paymentMonths))) {
                                let amount = 0;
                                let processingFee = 0;
                                if (!(_.isEmpty(paymentPackage))) {
                                    amount = paymentPackage.price;
                                }
                                if (!(_.isEmpty(paymentMonths))) {
                                    const payMonth = JSON.parse(paymentMonths);
                                    amount = amount * payMonth.value;
                                }

                                if (paymentPhone) {
                                    this.props.makePayment({
                                        name,
                                        email,
                                        phone,
                                        paymentPackage,
                                        paymentMonths: JSON.parse(paymentMonths),
                                        amount,
                                        validCode,
                                        app: systemInfo,
                                        processingFee,
                                        carrier: JSON.parse(carrier),
                                        paymentPhone,
                                        paymentDate,
                                        location
                                    });
                                } else {
                                    message.warning("Enter Phone #");
                                }
                            } else {
                                message.warning("Pick Months");
                            }
                        } else {
                            message.warning("Choose Wallet");
                        }
                    } else {
                        message.warning("Pick package");
                    }
                } else {
                    message.warning("Loading");
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    cancel() {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: '' });
        message.warning('Canceled');
    }

    onMakeForeignPayment(pack) {
        const { profile, paymentMonths, validCode, systemInfo } = this.state;
        console.log("we are here");
        console.log({ pack, profile });

        try {
            if (pack.index === null) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    if (!(_.isEmpty(paymentMonths))) {
                        const name = `${profile.companyName}`;
                        const phone = profile.phone;
                        const email = profile.email;
                        const location = profile.companyLocation;
                        const paymentDate = new Date();

                        this.props.makeForeignPayment({
                            name,
                            email,
                            phone,
                            paymentPackage: pack,
                            paymentMonths,
                            amount: pack.foreignPrice,
                            validCode,
                            app: systemInfo,
                            processingFee: 0,
                            paymentPhone: phone,
                            paymentDate,
                            location,
                            currency: "NGN"
                        });
                    } else {
                        message.warning("Pick Months");
                    }
                } else {
                    alert("Loading");
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    handlePackageClick(title) {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: title });
    }

    renderPopUp(pack) {
        const { flwUrl, packageTitle } = this.state; 
        if (flwUrl && packageTitle === pack.title) { 
            try {
                return (
                    <div>
                        <PaymentModal
                            flwUrl={flwUrl}
                            visible={true}
                        />
                    </div>
                );
            } catch (e) {
                console.log(e);
            }
        }
        // const { flwUrl } = this.state;
        // if (flwUrl) {
        //     try {
        //         //return navigation.navigate("WebScreen", { flwUrl, payment, callbackUrl: app.foreignCallback });
        //         return(
        //             <div>
        //                 <PaymentModal
        //                     flwUrl={flwUrl}
        //                     visible={true}
        //                 />
        //             </div>
        //         )
        //     } catch (e) {
        //         console.log(e);
        //     }
        // }
    }

    renderApplyPromoButton = () => {
        if (this.state.validCodeLoading) {
            return <Spinner/>
        } else {
            return (
                <button style={{backgroundColor: "#8FBC44", width: "10rem"}} onClick={() => this.onApplyPromo()}>
                    <span style={{margin: "0.5rem", fontFamily: "ramblaRegular"}}>Apply</span>
                </button>
            )
        }
    }

    renderInvoice({ dollar, amount, pack }) {
        let subTotal = 0;
        let discountPercent = 0;
        let discount = 0;
        let processingFee = 0;
        const app = this.state.systemInfo || {}; // Assuming `app` is a prop

        const { paymentMonths, validCode } = this.state;

        if (!(_.isEmpty(pack))) {
            subTotal = pack.price;
        }

        if (!(_.isEmpty(paymentMonths))) {
            const payMonth = JSON.parse(paymentMonths);
            subTotal = subTotal * payMonth.value;
        }

        //COMPUTE AFFILIATE CODE
        console.log({ validCode });
        if (!(_.isEmpty(validCode))) {
            try {
                //check if valid code has a discount percent
                if ("discountPercent" in validCode) {
                    discountPercent = validCode.discountPercent;
                    if (discountPercent > 0) {
                        discount  = subTotal * discountPercent;
                    } else {
                        if ("discountPercent" in app) {
                            discountPercent = app.discountPercent;
                            discount  = subTotal * discountPercent;
                        }
                    }
                } else {
                    if ("discountPercent" in app) {
                        discountPercent = app.discountPercent;
                        discount  = subTotal * discountPercent;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }


        if ("processingFee" in app) {
            const processing = app.processingFee;
            processingFee = subTotal * processing;
        }

        const total = (subTotal + processingFee) - discount;
        return (
            <div style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem 0.5rem",
            }}>
                <p style={{
                    fontSize: 14,
                    fontFamily: "myriadBold",
                    color: "#000"
                }}>Sub Total</p>
                <h3>{dollar ? "$" : "TSH."} {total.toLocaleString()}</h3>
            </div>
        )
    }

    render() {
        const {
            paymentPhone,
            carrier,
            paymentMonths,
            months,
            paymentLoading,
            payment,
            profile,
            packageTitle,
            success
        } = this.state;

        //determine input border color
        let inputTextColor;
        if (success === null) {
            inputTextColor = null;
        } else {
            if (success) {
                inputTextColor = "green";
            } else {
                inputTextColor = "red";
            }
        }

        //show foreign or local currency
        const dollar = profile.companyLocation.countryCode !== "TZ";
        const newMonths = months.map(month => ({label: month.title, value: JSON.stringify(month), index: month.value}));
        const orderedMonths = _.orderBy(newMonths, ['index'], ['asc']);

        return (
            <div className={"subscriptionContainer policy"}>
                <div className={'subscriptionBody'}>
                    <div className={'topHeader'}>
                        <h4 style={{ color: "gray"}}>Nafasi offers 4 subscription plans</h4>
                        <h5 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Choose a plan and start your journey into the Nafasi universe.</h5>
                    </div>

                    <div className={'choosePackageInputBox'}>
                        {
                            dollar ?
                                null :
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="phone-number">Phone Number:</label>
                                        <Input
                                            style={{width: '100%'}}
                                            allowClear
                                            maxLength={10}
                                            id="phone"
                                            value={paymentPhone}
                                            onChange={this.handlePhoneChange}
                                            placeholder="Enter phone number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="payment-method">Payment Method:</label>
                                        <Select
                                            placeholder={"Select payment method"}
                                            value={carrier}
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={this.handleSelectCarrier}
                                            options={[
                                                {
                                                    value: JSON.stringify({
                                                        carrierID: "airtelMoney",
                                                        title: "Airtel Money"
                                                    }),
                                                    label: 'Airtel Money',
                                                },
                                                {
                                                    value: JSON.stringify({carrierID: "ezyPesa", title: "Ezy Pesa"}),
                                                    label: 'Ezy Pesa',
                                                },
                                                {
                                                    value: JSON.stringify({carrierID: "mPesa", title: "M-Pesa"}),
                                                    label: 'M-Pesa',
                                                },
                                                {
                                                    value: JSON.stringify({carrierID: "tigoPesa", title: "Tigo Pesa"}),
                                                    label: 'Tigo Pesa'
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                        }


                        <div className="form-group">
                            <label htmlFor="payment-method">Duration:</label>
                            <Select
                                placeholder={"Pick your subscription duration"}
                                value={paymentMonths}
                                style={{
                                    width: "100%",
                                }}
                                onChange={this.handleSelectMonth}
                                options={orderedMonths}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="payment-method">Promo Code:</label>
                            <div style={{ display: "flex", }}>
                                <Input
                                    style={{ width: '100%', marginRight: "1rem", borderColor: inputTextColor, color: inputTextColor }}
                                    allowClear
                                    maxLength={10}
                                    id="affiliateCode"
                                    value={this.state.affiliateCode}
                                    onChange={this.handleAffiliateCode}
                                    placeholder="Enter promo code"
                                />
                                <div>
                                    {this.renderApplyPromoButton()}
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        paymentLoading ?
                            <div style={{
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "2rem"
                            }}>
                                <p style={{fontFamily: "ramblaBold", fontSize: "16px", color: "#0793d9"}}>Finish payment
                                    on your phone</p>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Spinner/>
                                </div>
                                <button style={{backgroundColor: "#8FBC44", width: "10rem", margin: "2rem auto"  }} onClick={() => this.props.subscriptionFieldUpdated({ prop: "paymentLoading", value: false })}>
                                    <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Cancel</span>
                                </button>
                            </div>
                            :
                            <div className={'cardsContainer'}>
                                {
                                    paymentPackages.map(pack => {
                                        let amount = 0;
                                        let period = 1;
                                        if (!(_.isEmpty(pack))) {
                                            if (dollar) {
                                                amount = pack.foreignPrice;
                                            } else {
                                                amount = pack.price;
                                            }
                                        }

                                        if (!(_.isEmpty(paymentMonths))) {
                                            const payMonth = JSON.parse(paymentMonths);
                                            amount = amount * payMonth.value;
                                            period = payMonth.value;
                                        }
                                        return(
                                            <div className={`${pack.className} subscriptionCard`} onClick={() => this.handlePackageClick(pack.title)}>
                                                <h2 className={'title'}>{pack.title}</h2>
                                                <img src={pack.icon} alt={'3 months subscription'} id={'subImage'}/>
                                                <div>
                                                    <h3>{dollar ? "$" : "TSH."} {amount.toLocaleString()}</h3>
                                                    <p>per {period} Month(s)</p>
                                                </div>
                                                <div>
                                                    {this.renderInvoice({ dollar, amount, pack })}
                                                </div>
                                                <div className={'subscribeBtnContainer'}>
                                                <div className={'buttonBox2'}>
                                                        <Popconfirm
                                                            title={`${dollar ? "C" : "Unlock your phone to c"}omplete your ${pack.title} subscription for on the USSD popup`}
                                                            icon={<HeartOutlined />}
                                                            onConfirm={() => dollar ? this.onMakeForeignPayment(pack) : this.onMakePayment(pack)}
                                                            onCancel={() => this.cancel()}
                                                            okText="Confirm"
                                                            cancelText="No"
                                                            overlayStyle={{ width: "400px" }}
                                                        >
                                                            <button style={{ backgroundColor: "#8FBC44", width: "10rem" }}>
                                                                <span style={{ margin: "0.5rem", fontFamily: "ramblaRegular"}}>Subscribe</span>
                                                            </button>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                                {this.renderPopUp(pack)}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { systemInfo } = state.system;
    const { profile } = state.profile;
    const { paymentPackage, carrier, paymentPhone, paymentMonths, months, packageTitle, paymentPackages, paymentLoading, transID, flwUrl,
        affiliateCode,
        validCode,
        validCodeLoading,
        success,

        subTotal,
        discount,
        discountPercent,
        processingFee,
        total } = state.subscription;

    return {
        systemInfo,
        profile,

        paymentPackage,
        carrier,
        paymentPhone,
        paymentMonths,

        months,
        packageTitle,
        paymentPackages,
        paymentLoading,
        transID,

        flwUrl,

        affiliateCode,
        validCode,
        validCodeLoading,
        success,

        subTotal,
        discount,
        discountPercent,
        processingFee,
        total,
    }
};

// Wrapper functional component to use hooks
function ChoosePackage(props) {
    const navigate = useNavigate();

    return <ChoosePackageBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment, validateAffiliateCode })(ChoosePackage);